import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import "@/vueInit.js";
import store from "./store.js";
import "../public/public.css";
Vue.config.productionTip = false;
import BaiduMap from "vue-baidu-map";
import scroll from 'vue-seamless-scroll';
import storage from '../utils/localStorage';

Vue.use(storage);
import Viewer from 'v-viewer'
import 'viewerjs/dist/viewer.css'
Vue.use(Viewer)
Viewer.setDefaults({
  Options: { 'inline': true, 'button': true, 'navbar': true, 'title': true, 'toolbar': true, 'tooltip': true, 
  'movable': true, 'zoomable': true, 'rotatable': true, 'scalable': true, 'transition': true, 
  'fullscreen': true, 'keyboard': true, 'url': 'data-source'},
  viewed: function (e) {
    console.log(e.type,'视图结束');
    // 索引为 1 的图片旋转20度
    // if(e.detail.index === 1){
      this.viewer.scale(4);
    // }
  },
})



// 缩略图插件
// import VuePreview from 'vue-preview';
// Vue.use(VuePreview);
import VuePreview from "vue-preview"
Vue.use(VuePreview, {
mainClass: "pswp–minimal–dark",
barsSize: {top: 0, bottom: 0},
captionEl: false,
fullscreenEl: false,
shareEl: false,
bgOpacity: 0.8,
tapToClose: true,
});

Vue.use(BaiduMap, { ak: "dcKo4szMgg8XGTzGMhq6TGinDDKoh6dd" });
//引入element-ui
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
//使用element-ui
Vue.use(ElementUI);
// 使用表格滚动
Vue.use(scroll)
// axios
let { post, get, put, deletefn } = require("./axios.js");
// import axios from 'axios';
// Vue.prototype.$ajax = axios;
// 引入echarts
import * as echarts from "echarts";
Vue.prototype.$echarts = echarts;
// 引入动画组件
import animated from "animate.css";
Vue.use(animated);
//定义全局变量
Vue.prototype.$post = post;
Vue.prototype.$get = get;
Vue.prototype.$put = put;
Vue.prototype.$deletefn = deletefn;


//路由守卫
// router.beforeEach((to, from, next) => {
//   //如果进入到的路由是登录页，则正常展示
//   if (to.path == '/Login') {
//     next();
//   } else if (!localStorage.getItem('username') || !localStorage.getItem('psw')) {
//     next('/Login');
//     //转入login登录页面，登录成功后会将token存入localStorage
//   } else {
//     next();
//   }
// })
new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
