import Vue from "vue";
import Router from "vue-router";

Vue.use(Router);
const router = new Router({
// export default new Router({
  mode: "history",
  base: "/",
  routes: [
    {
      path: "/",
      name: "/",
      redirect: "/Login",
    },

    // 菜单页面
    {
      path: "/Home",
      name: "/Home",
      component: () => import("./components/Home.vue"),
      children: [
        // 首页
        {
          path: "/index",
          name: "首页",
          component: () => import("./components/Index.vue"),
        },
        // 产品管理
        {
          path: "/deviceInfo",
          name: "产品管理",
          component: () => import("./components/ProductManager.vue"),
        },
        // 合作案例管理
        {
          path: "/collaborative",
          name: "合作案例管理",
          component: () => import("./components/Collaborative.vue"),
        },
        // 新闻管理
        {
          path: "/news",
          name: "新闻管理",
          component: () => import("./components/News.vue"),
        },
      ],
    },
    // 登录页
    {
      path: "/Login",
      name: "/Login",
      component: () => import("./components/Login.vue"),
    },
    // 忘记密码页
    {
      path: "/Forget",
      name: "/Forget",
      component: () => import("./components/ForgetPsw.vue"),
    },
  ],
});
router.beforeEach((to, from, next) => {
  if (to.meta.refresh) {
    // 如果路由的meta字段中包含refresh属性，表示需要刷新页面
   
    console.log('11')
    next() 
    window.location.reload();
  } else {
    next() // 正常路由跳转
  }
})
export default router
// 以下代码解决路由地址重复的报错问题(一劳永逸)
const originalPush = Router.prototype.push;
Router.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};
